import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    open: Boolean,
  };

  connect() {
    if (this.openValue) {
      this.show();
    }
  }

  show() {
    UIkit.modal(this.element).show();
  }

  hide() {
    UIkit.modal(this.element).hide();
  }
}
