import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="upload"
export default class extends Controller {
  static values = {
    url: String,
    redirectBaseUrl: String,
  };

  static targets = ["errorMessage", "fileContainer"];

  connect() {
    const bar = document.getElementById("js-progressbar");

    var self = this;
    const onError = function () {
      if (arguments.length > 0) {
        const message = arguments[0].xhr.response.errors.file[0];
        self.errorMessageTarget.innerHTML = message;
        self.fileContainerTarget.classList.add("border-red-500");
      }
    };

    const onSuccess = function () {
      if (arguments.length > 0) {
        const resp = arguments[0].response;
        Turbo.visit(`${self.redirectBaseUrlValue}/${resp.id}/configure`);
      }
    };

    UIkit.upload(".js-upload", {
      url: this.urlValue,
      multiple: false,
      type: "json",
      beforeSend: (e) =>
        (e.headers = {
          "X-CSRF-TOKEN": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        }),

      beforeAll: function () {},

      load: function () {},

      error: onError,

      complete: onSuccess,

      loadStart: function (e) {
        bar.removeAttribute("hidden");
        bar.max = e.total;
        bar.value = e.loaded;
      },

      progress: function (e) {
        bar.max = e.total;
        bar.value = e.loaded;
      },

      loadEnd: function (e) {
        bar.max = e.total;
        bar.value = e.loaded;
      },

      completeAll: function () {
        setTimeout(function () {
          bar.setAttribute("hidden", "hidden");
        }, 1000);
      },
    });
  }
}
