import { Controller } from "@hotwired/stimulus";
import Chart from "@stimulus-components/chartjs";

// Connects to data-controller="line-chart"
export default class extends Chart {
  connect() {
    super.connect();

    this.chart;
    this.options;
    this.defaultOptions;
  }

  update() {
    // console.log(this.chart.data.datasets.push([1, 100, 90, 200, 209]));
    console.log(this.chart.data.datasets);
    this.chart.update();
  }

  get defaultOptions() {
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 3,
      lineTension: 0.1,
      scales: {
        x: {
          grid: {
            display: false, // Removes grid lines on the x-axis
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              size: 11,
            },
            color: "gray",
          },
        },
        y: {
          beginAtZero: true,
          // leaving those here so we remember that options does exist :)
          // suggestedMin: minData - buffer,
          // suggestedMax: maxData + buffer,
          grid: {
            display: false, // Removes grid lines on the y-axis
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              size: 11,
            },
            color: "gray",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}
