import { Controller } from "@hotwired/stimulus";
import Chart from "@stimulus-components/chartjs";

export default class extends Chart {
  connect() {
    super.connect();

    this.chart;
    this.options;
    this.defaultOptions;
  }

  get defaultOptions() {
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 3,
      scales: {
        x: {
          grid: {
            display: false, // Removes grid lines on the x-axis
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              size: 11,
            },
            color: "gray",
          },
        },
        y: {
          // leaving those here so we remember that options does exist :)
          // suggestedMin: minData - buffer,
          // suggestedMax: maxData + buffer,
          grid: {
            display: false, // Removes grid lines on the y-axis
          },
          border: {
            display: false,
          },
          ticks: {
            font: {
              size: 11,
            },
            color: "gray",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}
