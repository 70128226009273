import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notice"
export default class extends Controller {
  static targets = ["notice"];

  connect() {
    setTimeout(() => {
      this.noticeTarget.remove();
    }, 3000);
  }
}
